import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper';
import Wrapper from '../../components/Wrapper'
import TinyCapsTypo from '../../typography/TinyCapsTypo'
import GiganticTypo from '../../typography/GiganticTypo'
import RegularTypo from '../../typography/RegularTypo'
import OutlinedButton from '../../molecules/OutlinedButton'
import { Link } from 'gatsby'
import { breakpoints, maxWidth } from '../../utils/theme'
import { useTranslation } from 'react-i18next'
import slugify from 'slugify'
import { germany } from '../../initial/vars'

SwiperCore.use([Pagination]);

const SwiperWrapper = styled.div`
    .swiper-pagination {

        .swiper-pagination-bullet {
            width: 7px;
            height: 7px;
            background-color: white;
            margin: 0 26px 0 6px !important;
        }
        
        text-align: left;
        margin-left: auto !important;
        margin-right: auto !important;
        left: 0 !important; 
        bottom: 45px !important;
        max-width: ${maxWidth}px;
        padding:  0 48px !important;
      
        @media (max-width: ${breakpoints.mobile + 'px'}) { 
          padding:  0 14px !important;
          left: 0 !important;
        }
    }
`

const OuterWrapper = styled.div`
    position: absolute;
    z-index: 99999;
    right: 0;
    left: 0;
    height: 100%;
    box-sizing: border-box;
     
    > div {
        color: white;
        display: flex;
        height: 100%;
        align-items: center;
    }
`



const HeroSwiper = ({ data }) => {

    const { t, i18n } = useTranslation()
    const slug = (name) => {
      return slugify(t(`product::${name}`), { lower: true }).replace(/[^a-z0-9-]/gi, '')
    }
    const curretnLang = i18n.language 
  const prefix = germany ? curretnLang + '/' : ''
    
    return (
        <SwiperWrapper  >
            <Swiper
                loop
                pagination={{ clickable: true }}
            >
                {data.map((swiper,index) => (
                    <SwiperSlide key={index} >
                        <OuterWrapper>
                            <Wrapper>
                                <div style={{ maxWidth: 470, marginTop: -24 }} >
                                    <TinyCapsTypo text={t(swiper.family)} />
                                    <GiganticTypo text={t(swiper.series)} />
                                    <div style={{ margin: '24px 0 48px' }} >
                                        <RegularTypo text={t(swiper.copy)} />
                                    </div>
                                    <Link  
                                     to={`/${prefix}${slug('Produkty')}/?filter=${swiper.link}`} 
                                    >
                                        <OutlinedButton text={t("Zobacz więcej")}/>
                                    </Link>
                                </div>
                            </Wrapper>
                        </OuterWrapper>
                        {swiper.image}
                    </SwiperSlide>
                ))}
            </Swiper>
        </SwiperWrapper>
    )
}

export default HeroSwiper
