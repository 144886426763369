import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'
import HeroSwiper from '../components/HeroSwiper'
import HomePageProducts from '../components/HomePageProducts'
import ImageHeroPuraDesktop from '../components/images/imageHeroPuraDesktop'
import ImageHeroSDesktop from '../components/images/imageHeroSDesktop'
import ImageHeroGDesktop from '../components/images/imageHeroGDesktop'
import ImageHeroLoftDesktop from '../components/images/imageHeroLoftDesktop'

const HeroData = [
 {
  family: 'System przesuwny',
  series: 'Pura',
  copy: 'hero-copy-pura',
  link: 'pura',
  image: <ImageHeroPuraDesktop />
 },
 {
  family: 'Systemy przesuwne',
  series: 'Seria S',
  copy: 'hero-copy-s-series',
  link: 's-series',
  image: <ImageHeroSDesktop />
 },
 {
  family: 'Systemy przesuwne',
  series: 'Seria G',
  copy: 'hero-copy-g-series',
  link: 'g-series',
  image: <ImageHeroGDesktop />
 },
 {
  family: 'Systemy przesuwne',
  series: 'Seria Loft',
  copy: 'hero-copy-loft-series',
  link: 'tycho-loft',
  image: <ImageHeroLoftDesktop />
 }
]

const IndexPage = () => {
 return (
  <Layout>
   <SEO title="Home" />
   <div style={{ marginBottom: 60 }}>
    <HeroSwiper data={HeroData} />
    <HomePageProducts />
   </div>
  </Layout>
 )
}

export default IndexPage
