import React from 'react'
import styled from 'styled-components'

const TinyCapsTypoContainer = styled.div`
  ${({ fontSize }) =>
    fontSize ? `font-size: ${fontSize}` : `font-size: 10px`};
  text-transform: uppercase;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0.8px;
  ${({ color }) => `color: ${color}`};
  ${({ oneline }) => oneline && `text-overflow: ellipsis`};
  ${({ oneline }) => oneline && `white-space: nowrap`};
  ${({ oneline }) => oneline && `overflow: hidden`};
`
export default function TinyCapsTypo({ text, oneline, color, fontSize }) {
  return (
    <TinyCapsTypoContainer oneline={oneline} fontSize={fontSize} color={color}>
      {text}
    </TinyCapsTypoContainer>
  )
}
