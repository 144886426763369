import React from 'react'
import styled from 'styled-components'

const GiganticTypoContainer = styled.div`
  font-size: 66px;
  line-height: 72px;
  font-weight: 300;
  margin-top: 8px;
  margin-left: -2px;
  margin-bottom: 8px;
  ${({ color }) => `color: ${color}`};
`
export default function GiganticTypo({ text, style, color }) {
  return (
    <GiganticTypoContainer style={style} color={color}>
      {text}
    </GiganticTypoContainer>
  )
}
