import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import ProductGrid from '../../molecules/ProductGrid'
import ProductCardData from '../../molecules/ProductCardData'
import { fromMostExpensive } from '../../utils/utils'
import { typoLightGray } from '../../utils/theme'
import Wrapper from '../../components/Wrapper'
import TinyCapsTypo from '../../typography/TinyCapsTypo'
import GiganticTypo from '../../typography/GiganticTypo'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import slugify from 'slugify'
import { germany } from '../../initial/vars'

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  
  > div {
    display: inline-block;
  }
`

const HomePageProducts = () => {
  const data = useStaticQuery(graphql`
    query HomePageProducts {
      allWcProducts(
        filter: {
          stock_quantity: {ne: 0},
          tags: { elemMatch: { name: { eq: "Bestseller" } } }
        }
      ) {
        edges {
          node {
            wordpress_id
            name
            short_description
            slug
            price
            tags {
              name
            }
            categories {
                slug
            }
            acf {
              bullet_1
              bullet_2
              bullet_3
              euro_price
            }
            images {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 645, maxHeight: 420) {
                    ...GatsbyImageSharpFluid
                  }
                }
              } 
            }
          }
        }
      }
    }
  `)

  const HomeProductsData = [
    {
      family: "Systemy przesuwne",
      series: "Seria S",
      link: "s-series",
      filter: edges => edges.node.categories.some(category => category.slug === 's-series')
    },
    {
      family: "Systemy przesuwne do szkła",
      series: "Seria G",
      link: "g-series",
      filter: edges => edges.node.categories.some(category => category.slug === 'g-series')
    },
    {
      family: "Systemy przesuwne",
      series: "Seria Loft",
      link: "tycho-loft",
      filter: edges => edges.node.categories.some(category => category.slug === 'tycho-loft')
    },
    // {
    //   family: "Systemy do lekkich drzwi przesuwnych",
    //   series: "Seria L",
    //   link: "l-series",
    //   filter: edges => edges.node.categories.some(category => category.slug === 'l-series')
    // },
    {
      family: "Systemy składane",
      series: "Seria F",
      link: "f-series",
      filter: edges => edges.node.categories.some(category => category.slug === 'f-series')
    },
    {
      family: "Systemy do drzwi przesuwnych \n w szafach i zabudowach wnęk",
      series: "Seria W",
      link: "w-series",
      filter: edges => edges.node.categories.some(category => category.slug === 'w-series')
    }
  ]

  const { t, i18n } = useTranslation()
  const slug = (name) => {
    return slugify(t(`product::${name}`), { lower: true }).replace(/[^a-z0-9-]/gi, '')
  }
  const curretnLang = i18n.language 
  const prefix = germany ? curretnLang + '/' : ''

  return (
    <Wrapper productList>
      {HomeProductsData.map((section,index) => (
        <div 
          key={index}
          style={{ marginTop: 48, textAlign: 'center' }} 
        >

          <TinyCapsTypo text={t(section.family)} color={typoLightGray} />
          <StyledLink 
            to={`/${prefix}${slug('Produkty')}/?filter=${section.link}`} 
          >
            <GiganticTypo text={t(section.series)} color={typoLightGray} />
          </StyledLink>

          <ProductGrid homepage>
            {data.allWcProducts.edges
              .filter(section.filter)
              .sort(fromMostExpensive)
              .slice(0, 4)
              .map(edge => (
                <ProductCardData
                  key={edge.node.wordpress_id}
                  product={edge.node}
                />
              ))}
          </ProductGrid>
        </div>
      ))}
    </Wrapper>
  )
}

export default HomePageProducts
