import React from 'react'
import styled from 'styled-components'
import TinyCapsTypo from '../../typography/TinyCapsTypo'

const OutlinedWrapper = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  ${({ color }) => `color: ${color}`};
  ${({ color }) => `border: 1px solid ${color}`};
  cursor: pointer;
  user-select: none;
  max-width: 220px;
`

export default function ProductCard({ style, text, color = 'white' }) {
  return (
    <OutlinedWrapper style={style} color={color}>
      <TinyCapsTypo color={color} text={text} />
    </OutlinedWrapper>
  )
}
